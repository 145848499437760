.breadcrumb {
  background-color: $gray-800;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: $gray-500;
}

.breadcrumb-item.active {
  color: $gray-500;
}

// Table of content
// ================

.toc {
  position: sticky;
  top: 4rem;
  font-size: $font-size-sm;
  max-height: 100vh;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    ul {
      padding-left: 1rem;
    }
  }

  li {
    margin: 0;
  }

  a {
    color: inherit;
    opacity: 0.8;
    word-wrap: break-word;

    &:hover {
      opacity: 1;
    }
  }
}

// Scrollbars
$scrollbar-color: transparent;
$scrollbar-thumb-color: rgba(255, 255, 255, 0.2);

$dark-mode-body-bg-color: $gray-900;
$dark-mode-text-color: $gray-500;
$dark-mode-secondary: $gray-800;
$dark-mode-bg-color: $dark;
$dark-mode-border-color: $gray-700;

// Table
$dark-mode-table-border-color: $gray-600;

.carousel-control-prev-icon,
.carousel-control-next-icon {
  padding: 1rem;
  border-radius: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 1rem 1rem;
}

.carousel-caption {
  border-radius: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
}

.alert {
  border-width: 0 0 0 4px;
  color: inherit;

  &.alert-success {
    border-color: $success;
    background-color: transparentize($success, 0.8);
  }

  &.alert-info {
    border-color: $info;
    background-color: transparentize($info, 0.8);
  }

  &.alert-warning {
    border-color: $warning;
    background-color: transparentize($warning, 0.8);
  }

  &.alert-danger {
    border-color: $danger;
    background-color: transparentize($danger, 0.8);
  }

  > p,
  > ol,
  > ul,
  > pre {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.megamenu {
  position: fixed;
  left: 0;
  right: 0;
  top: 56px;
  font-family: $font-family-sans-serif;
  font-size: 16px;
  line-height: 1.5;
  margin-top: 2px;
  background-color: $teal;
  color: $light;
  z-index: $zindex-dropdown;
}

.megamenu .row {
  padding-bottom: 16px;
}

.megamenu-heading {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.2;
  margin-top: 16px;
  margin-bottom: 0;
}

.megamenu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.megamenu a {
  color: inherit;
  text-decoration: none;
}

.megamenu svg {
  vertical-align: -2.4px;
}

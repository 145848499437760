.alert-success .alert-link {
  color: $success;
}

.alert-danger .alert-link {
  color: $danger;
}

.alert-warning .alert-link {
  color: $warning;
}

.alert-info .alert-link {
  color: $info;
}

@media (prefers-color-scheme: dark) {
  @import "variables";
  @import "alert";
  @import "body";
  @import "breadcrumb";
  @import "button";
  @import "card";
  @import "code";
  @import "dropdown";
  @import "footer";
  @import "form";
  @import "list-group";
  @import "misc";
  @import "modal";
  @import "nav";
  @import "navbar";
  @import "scrollbar";
  @import "table";
}

.form-control {
  &:focus {
    background-color: $dark-mode-bg-color;
    color: $dark-mode-text-color;
  }
}

.form-control,
.custom-file-label,
.custom-select,
.custom-control-label::before {
  border-color: $dark-mode-border-color;
  background-color: $dark-mode-bg-color;
  color: $dark-mode-text-color;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $dark-mode-text-color;
    opacity: 0.5; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $dark-mode-text-color;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $dark-mode-text-color;
  }
}

.custom-file-label::after {
  background: transparent;
  color: $dark-mode-text-color;
}

.custom-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23fff' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
}

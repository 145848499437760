pre {
  padding: $spacer;
  background: $gray-200;
  border-radius: $border-radius;
}

// When you don't want to escape wiki code inside <pre>, you can use
// <div class="pre"></div> instead.
.pre {
  @extend pre;
}

.hljs {
  background: transparent !important;
  padding: 0 !important;
}
